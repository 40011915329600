import React from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown/with-html"
import ReactHtmlParser from 'react-html-parser';
const OFFICE_DETAILS = gql`
  query GetOffice($id: String!) {
    offices(where: { Office_CRM_ID: $id }) {
      id
      Area
      Office_Name
      Office_Address
      Opening_hours
      Select_Office_Location
    }
    defaultoffice: offices (where:{Title:"Clapham Office"}) {
      id
      Area
      Office_Name
      Office_Address
      Opening_hours
      Select_Office_Location
    }
  }
`

const GetOfficeBlockDetails = props => {
  let office_crm_mapping = {"5":"ORM"}
  let office_crm_id = office_crm_mapping[props.id] || props.id
  const { loading, data } = useQuery(OFFICE_DETAILS, {
    variables: { id: office_crm_id },
  })

  var Clapham_Timing = "Call us six days a week:<br />Mon-Thu - 09:00-19.00<br />Fri - 09:00-18:00<br />Sat -09:30-16:00";
  var Battersea_Timimg = "Call us six days a week<br />Mon-Fri 09:00 AM-06:00 PM<br />Sat 09:30AM-04:00PM"
  var Manchester_Timing = "Call us six days a week:<br />Mon-Fri 09:00 AM-06:00 PM<br />Sat 10:00AM-04:00PM"
  var London_Office_Timing = "Call us six days a week:<br />Monday - Friday: 9:00 am - 6:00 pm<br />Saturday: 9:30 am - 4:00 pm";

  const Clapham_Url = "/contact/our-offices/london-offices/clapham-estate-agents"
  const Manchester_Url = "/contact/our-offices/manchester-offices/manchester-property-investments"
  const Battersea_Url = "/contact/our-offices/london-offices/battersea-estate-agents-and-nine-elms-estate-agents"
  const Map_Manchester = "https://www.google.com/search?q=orlando+reid+manchester&rlz=1C1RXQR_enIN976IN976&oq=orlando+reid+ma&aqs=chrome.1.69i57j69i59j69i60l3.3768j0j9&sourceid=chrome&ie=UTF-8#lrd=0x487bb1d91e0cc4b1:0x81072e0fb09326d4,1";
  const Map_Clapham_Url = "https://www.google.com/search?q=orlando+reid+clapham&rlz=1C1RXQR_enIN1006IN1006&oq=orlando+reid+clapham&aqs=chrome.0.69i59l2j0i22i30l2j0i390j69i60l3.3744j0j7&sourceid=chrome&ie=UTF-8#lrd=0x487605acd1f864b5:0x92bfa6fc6400275c,1";
  const Map_Battersea_Url = "https://www.google.com/search?q=orlando+reid+battersea+and+nine+elms&rlz=1C1RXQR_enIN976IN976&oq=orlando+reid+batte&aqs=chrome.1.69i57j69i59j69i60l2j69i61.3630j0j9&sourceid=chrome&ie=UTF-8#lrd=0x487605a48010e231:0xed129ee181ace4dd,1";
  
  
  const offices = data?.offices?.length !== 0 ? data?.offices : data?.defaultoffice;

  return (
    <div className="getOffice_details col-12">
      {offices?.map((item, index) => {
        var office_name = item.Office_Name.replace("and Clapham Letting Agents", " ")
        var timimg = item.Select_Office_Location == "London" ? London_Office_Timing : (item.Office_Name.toLowerCase().includes("manchester") ? Manchester_Timing : item.Office_Name.toLowerCase().includes("battersea") ? Battersea_Timimg : item.Office_Name.toLowerCase().includes("clapham") ? Clapham_Timing : "");
        var link  = item.Office_Name.toLowerCase().includes("manchester") ? Manchester_Url : item.Office_Name.toLowerCase().includes("battersea") ? Battersea_Url : item.Office_Name.toLowerCase().includes("clapham") ? Clapham_Url : "";
        var map_url  = item.Office_Name.toLowerCase().includes("manchester") ? Map_Manchester : item.Office_Name.toLowerCase().includes("battersea") ? Map_Battersea_Url : item.Office_Name.toLowerCase().includes("clapham") ? Map_Clapham_Url : "";
        //console.log(link, "timimg")
        return (
          <div>
            <Link to={link} className="office_name_title">
            <strong className="office_name">{office_name}</strong>
            </Link>
            <p className="office_address">{item.Office_Address}</p>
            <p className="office_timing">{ReactHtmlParser(timimg?.replace(/\n/g, '<br />'))}</p>
            
          </div>
        )
      })}
      <strong></strong>
    </div>
  )
}

export default GetOfficeBlockDetails
