import React from 'react';
import TeamImg from "../images/placeholder.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import { Button, Container, Row, Col} from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import $ from 'jquery';
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
import { VideoPlayButton, Logo } from './icon';
import OurOffice from './our-office';
// import ScrollAnimation from 'react-animate-on-scroll';

const GET_ALL_TEAMS =  gql`
  query GetTeams {    
    teams(sort:"Sort:asc") {
        id
        Name
        URL
        Designation
        Embed_Video_URL
        Staff_Image {
        url
        }
        imagetransforms
    }
  }
`;


function Teamlisting() {
const { loading:teamsloading, error:teamserror, data:allteams } = useQuery(GET_ALL_TEAMS);
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoindex, setVideoindex] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const playVideo = (video_index, video_id,e) => {  
    setVideoindex(video_index)   
    setShowVideo(true)
}
const trackerVideo = (event) => {    
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'Video Play Btn',
    'formType': event,
    'formId': 'Video Play',
    'formName': 'Video',
    'formLabel': 'Video'
  });
}
  if (teamsloading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);

    return (
        <section className="reid-team">
        {/* <ScrollAnimation offset={100} animateIn="animate__fadeInUp" animateOnce={true} > */}
        <Container>
        <Row>
            {allteams.teams.map((item, index) => {  
                 let processedImages = JSON.stringify({});
                 if (item?.imagetransforms?.Staff_Image_Transforms) {
                     processedImages = item.imagetransforms.Staff_Image_Transforms;
                 }
                if (item.Embed_Video_URL) {
                 var videoid = getVideoId(item.Embed_Video_URL);
                }
                return (
              item.Staff_Image &&
                <Col md={6} className="col-xl-4">
                <div className="team-wrap text-center">
                <div className="playing-video">
                    <figure className={`team-img team_img_${index}`}>
                    <div className="bwimg">
                    <ImageTransform imagesources={item.Staff_Image && item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.teamlistimg"attr={{ alt: item.Name+ ' '+item.Desgination+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={item.id}/>
                    </div>
                        {item.Embed_Video_URL &&
                        <button className="btn-play"
                            onClick = { (e) => {playVideo(index, videoid.id)}}>
                            <VideoPlayButton />
                        </button>
                        }
                        {showVideo && videoindex== index &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setPlay(false);setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(item.Name)}

                        />
                        }
                    </figure>
                    <Link to={`${item.URL}/ `}>
                    <div className="team-info">
                        <h3>{item.Name}</h3>
                        <span className="job-title">{item.Designation}</span>
                    </div>
                    </Link>
                </div>
            

                </div>
                </Col>
                )
            })}
        </Row>
        </Container>
        {/* </ScrollAnimation> */}
      </section>

    )

}

export default Teamlisting;
