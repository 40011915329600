import React, { useState } from "react";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import GeneralEnquiryForm from "./forms/developments-enquiry";
import SalesForceForm from "../components/forms/salesforce-form"
import { Logo } from '../components/icon';

function TeamDetails(props) {
  const [show, setShow] = useState(false);


  return (
    <>
        
    <a href="javascript:;" className="btn btn-success triggerbooking" onClick={() => setShow(true)}>Enquire now</a>
	<Modal
        show={show}
        centered={true}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w modal-form"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          Enquire now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.propertyCity?.toLowerCase() == "manchester" ?
            <> 
              <SalesForceForm className="sidebar" propertyName={props.propertyName}  /> 
            </>
          :
            <GeneralEnquiryForm  propertyName={props.propertyName}  propertyurl={props.propertyurl} to_email_id={props.propertyCity?.toLowerCase() == "london" ? "sales@orlandoreid.co.uk" : "manchester@orlandoreid.co.uk"}/> 
          } 
        </Modal.Body>
      </Modal>
			      
   
    </>
  )
}

export default TeamDetails